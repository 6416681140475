import Vue             from 'vue'
import Vuex            from 'vuex'
import entitiesApi     from '@/api/v1/entities'
import router          from '@/router'
import cloneDeep       from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newEntity = {
  entity_name: '',
  address: '',
  country: '',
  ceo_name: '',
  business_conditions: '',
  business_item: '',
  company_type: '',
  currency: '',
  entity_comment: '',
  entity_type: '',
  is_surtax: false,
  business_license_number: '',
  english_client_name: '',
  birth_date: '',
  resident_number: '',
  id: 0
}

const state = () => ({
  entities: [],
  drilldownEntity: cloneDeep(newEntity),
})


const getters = {
  drilldownEntityId: state => {
    return state.drilldownEntity ? state.drilldownEntity.id : 0
  },
  drilldownEntityName: state => {
    return state.drilldownEntity.entity_name
  },
  drilldownEntityRequiredFieldsValid: (state, getters) => {
    return getters.nameValid && getters.entityTypeValid &&
      (getters.isCompanyType ? (getters.companyTypeValid && getters.businessLicenseNumberValid) : true)
  },
  businessLicenseNumberValid: state => {
    return !!state.drilldownEntity.business_license_number
  },
  companyTypeValid: state => {
    return !!state.drilldownEntity.company_type
  },
  entityTypeValid: state => {
    return !!state.drilldownEntity.entity_type
  },
  isCompanyType: state => {
    return state.drilldownEntity.entity_type !== '개인'
  },
  nameValid: state => {
    return !!state.drilldownEntity.entity_name
  },
  getField,
}


const actions = {
  getBusinessLicenseNumberValid ({ state }) {
    return new Promise(resolve => {
      entitiesApi.getBusinessLicenseNumberValid(state.drilldownEntity.business_license_number).then(resp => {
        resolve(resp)
      })
    })
  },
  getEntities ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.getEntities(filters).then(resp => {
        commit('setEntities', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getEntity (context, entityId) {
    return new Promise(resolve => {
      entitiesApi.getEntity(entityId).then(resp => {
        resolve(resp)
      })
    })
  },
  searchEntitiesByName (context, searchEntityText) {
    return new Promise(resolve => {
      entitiesApi.searchEntities(searchEntityText).then(resp => {
        resolve(resp)
      })
    })
  },
  entityDrilldownOpen ({ commit, dispatch }, entityId) {
    commit('setDrilldownEntity', {id: entityId})
    dispatch('sidepanelOpen', { componentName: 'entity-drilldown' }, { root : true })
  },
  resetDrilldownEntity ({ commit }) {
    let newQueryParams = cloneDeep(router.history.current.query)
    if (Object.prototype.hasOwnProperty.call(newQueryParams, 'entity_id')) {
      delete newQueryParams['entity_id']
      router.push({ query: newQueryParams })
    }
    commit('setDrilldownEntity', cloneDeep(newEntity))
  },
  updateEntity ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.updateEntity(state.drilldownEntity.id, state.drilldownEntity).then(resp => {
        commit('updateDrilldownInList', resp)
        commit('setDrilldownEntity', newEntity)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  uploadEntity (context, fileInfo) {
    let data = new FormData()
    data.append('id', fileInfo.id)
    data.append('attached_file', fileInfo.attachedFile)
    return new Promise(resolve => {
      entitiesApi.uploadEntity(data).then(resp => {
        resolve(resp)
      })
    })
  },
  deleteEntity ({ commit, dispatch }, entityId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.deleteEntity(entityId).then(resp => {
        if (resp) {
          commit('removeEntityFromList', resp.id)
          commit('setDrilldownEntity', newEntity)
        } else {
          console.error('Delete Failed!!!')
        }
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  newEntitySetName ({ commit, dispatch }, entityName) {
    dispatch('resetDrilldownEntity')
    commit('setDrilldownEntityName', entityName)
  },
  createEntityWithName ({ dispatch }, entityName) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dispatch('newEntitySetName', entityName)
      dispatch('createEntity').then(resp => resolve(resp))
    })
  },
  createEntity ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.postEntity(state.drilldownEntity).then(resp => {
        if (resp) {
          commit('addEntity', resp)
        }
        dispatch('deactiveLoading', null, { root : true })
        dispatch('resetDrilldownEntity')
        resolve(resp)
      })
    })
  },
}


const mutations = {
  addEntity (state, entity) {
    state.entities.unshift(entity)
  },
  removeEntityFromList (state, entityId) {
    const index = state.entities.findIndex(entity => entity.id === entityId)
    if (index >= 0) {
      state.entities.splice(index, 1)
    }
  },
  setDrilldownEntityName (state, name) {
    state.drilldownEntity.entity_name = name
  },
  setEntities (state, fromApi) {
    state.entities = fromApi
  },
  setDrilldownEntity (state, entity) {
    state.drilldownEntity = entity
  },
  updateDrilldownName (state, entityName) {
    state.drilldownEntity.entity_name = entityName
  },
  updateDrilldownInList (state, entity)  {
    const index = state.entities.findIndex(c => c.id === entity.id)
    if (index >= 0) {
      state.entities.splice(index, 1, entity)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
