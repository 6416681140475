<template>
  <tr>
    <td class='pr-2 py-1'>
      <user-search
        :user-ids='updatingEarning.user_id'
        :user-names='updatingEarning.user_name'
        role='lawyer'
        class='w-64'
        @update-selected-user='updateEarningUser($event)' />
    </td>
    <td class='px-2 py-1 text-left'>
      <select
        v-model='updatingEarning.user_position'
        class='form-select w-30'>
        <option
          v-for='userPosition in selectableUserPositions'
          :key='`case-timesheet-user-position-${userPosition}`'
          :value='userPosition'>
          {{userPosition}}
        </option>
      </select>
    </td>
    <td class='px-2 py-1 text-right'>{{ billableHoursString }}</td>
    <td class='px-2 py-1 text-right'>
      <input
        type='number'
        class='form-input w-16 inline-block text-right'
        v-model='updatingEarning.invoice_total_pool_ratio'>
      %
    </td>
    <td class='px-2 py-1 text-right text-gray-500'>{{ numberFormat(updatingEarning.amount) }} {{ invoiceCurrency }}</td>
    <td class='px-2 py-1'>{{updatingEarning.updated_by_name}}</td>
    <td class='py-1 text-center'>
      <x-circle-icon
        @click='removeEarning'
        class='standard-transition cursor-pointer text-red-600 inline-block border border-transparent rounded-full hover:border-red-600' />
    </td>
  </tr>
</template>

<script>
import { mapState }    from 'vuex'
import { XCircleIcon } from '@vue-hero-icons/outline'
import numbro          from 'numbro'
import UserSearch from '@/components/UserSearch.vue'

export default {
  name: 'CaseInvoiceDistributionEarningsRow',
  components: {
    XCircleIcon,
    UserSearch,
  },
  props: [
    'earning',
  ],
  data () {
    return {
      updatingEarning: {}
    }
  },
  watch: {
    'editingInvoice.total_amount': {
      handler: function () {
        this.updateEarningAmount()
      },
      immediate: true
    },
    'updatingEarning.invoice_total_pool_ratio': {
      handler: function () {
        this.updateEarningAmount()
        this.$emit('update-per-pool-ratios')
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    ...mapState('timesheetEntries', [
      'selectableUserPositions',
    ]),
    invoiceCurrency () {
      return this.$t('krw') // need to get this from the case
    },
    billableHoursString () {
      // I have no idea what this means? where and why do we have this? Hana: 2021-09-5
      return (this.updatingEarning.billable_hours) ? numbro(this.updatingEarning.billable_hours).format({ thousandSeparated: true, mantissa: 1 }) : '-'
    },
  },
  methods: {
    removeEarning () {
      this.$emit('remove-earning')
    },
    updateEarningAmount () {
      this.updatingEarning.amount = parseFloat(this.updatingEarning.invoice_total_pool_ratio) * parseFloat(this.editingInvoice.total_amount) / 100
    },
    numberFormat (num) {
      return numbro(num).format({ thousandSeparated: true, mantissa: 0 })
    },
    updateEarningUser (userIdAndName) {
      this.updatingEarning.user_id   = userIdAndName.user_id
      this.updatingEarning.user_name = userIdAndName.user_name
    },
  },
  mounted () {
    this.updatingEarning = this.earning
  },
}
</script>
