import Vue           from 'vue'
import VueRouter     from 'vue-router'
import { authGuard } from '../auth/authGuard'
import Home          from '../views/Home.vue'

const Contacts              = () => import(/* webpackChunkName: "baseApp" */ '../views/Contacts.vue')
const CustomFieldTemplates  = () => import(/* webpackChunkName: "baseApp" */ '../views/CustomFieldTemplates.vue')
const Dashboard             = () => import(/* webpackChunkName: "baseApp" */ '../views/Dashboard.vue')
const Entities              = () => import(/* webpackChunkName: "baseApp" */ '../views/Entities.vue')
const DrilldownContainer    = () => import(/* webpackChunkName: "baseApp" */ '../views/DrilldownContainer.vue')
const NewUser               = () => import(/* webpackChunkName: "baseApp" */ '../views/NewUser.vue')
const History               = () => import(/* webpackChunkName: "baseApp" */ '../views/History.vue')
const ManageAccess          = () => import(/* webpackChunkName: "baseApp" */ '../views/ManageAccess.vue')
const MyApprovals           = () => import(/* webpackChunkName: "baseApp" */ '../views/MyApprovals.vue')
const RequiredItems         = () => import(/* webpackChunkName: "baseApp" */ '../views/RequiredItems.vue')
const Deliveries            = () => import(/* webpackChunkName: "baseApp" */ '../views/Deliveries.vue')
const MyPreferences          = () => import(/* webpackChunkName: "baseApp" */ '../views/MyPreferences.vue')

const Invoices                  = () => import(/* webpackChunkName: "modeCase" */ '../views/Invoices.vue')
const Timesheet                 = () => import(/* webpackChunkName: "modeCase" */ '../views/Timesheet.vue')
const Expenses                  = () => import(/* webpackChunkName: "modeCase" */ '../views/Expenses.vue')
const Deposits                  = () => import(/* webpackChunkName: "modeCase" */ '../views/Deposits.vue')
const LawyerPerformanceReport   = () => import(/* webpackChunkName: "modeCase" */ '../views/LawyerPerformanceReport.vue')
const LawyerMyPerformanceReport = () => import(/* webpackChunkName: "modeCase" */ '../views/LawyerMyPerformanceReport.vue')
const CasePerformanceReport     = () => import(/* webpackChunkName: "modeCase" */ '../views/CasePerformanceReport.vue')
const MagamPerformances         = () => import(/* webpackChunkName: "modeCase" */ '../views/MagamPerformances.vue')
const MyCases                   = () => import(/* webpackChunkName: "modeCase" */ '../views/MyCases.vue')
const InvoicePdfCreator         = () => import(/* webpackChunkName: "modeCase" */ '../views/InvoicePdfCreator.vue')
const Schedules                  = () => import(/* webpackChunkName: "modeCase" */ '../views/Schedules.vue')

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    beforeEnter: authGuard,
  },
  {
    path: '/my_preferences',
    name: 'MyPreferences',
    component: MyPreferences,
    beforeEnter: authGuard,
  },
  {
    path: '/custom_field_templates',
    name: 'CustomFieldTemplates',
    component: CustomFieldTemplates,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/required_items',
    name: 'RequiredItems',
    component: RequiredItems,
    beforeEnter: authGuard,
  },
  {
    path: '/deliveries',
    name: 'Deliveries',
    component: Deliveries,
    beforeEnter: authGuard,
  },
  {
    path: '/drilldown',
    name: 'DrilldownContainer',
    component: DrilldownContainer,
    beforeEnter: authGuard,
  },
  {
    path: '/entities',
    name: 'Entities',
    component: Entities,
    beforeEnter: authGuard,
  },
  {
    path: '/investments',
    name: 'Investments',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/new_user',
    name: 'NewUser',
    component: NewUser,
    beforeEnter: authGuard,
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    beforeEnter: authGuard,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    beforeEnter: authGuard,
  },
  {
    path: '/manage_access',
    name: 'ManageAccess',
    component: ManageAccess,
    beforeEnter: authGuard,
  },
  {
    path: '/my_approvals',
    name: 'MyApprovals',
    component: MyApprovals,
    beforeEnter: authGuard,
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: Timesheet,
    beforeEnter: authGuard,
  },
  {
    path: '/expenses',
    name: 'Expenses',
    component: Expenses,
    beforeEnter: authGuard,
  },
  {
    path: '/deposits',
    name: 'Deposits',
    component: Deposits,
    beforeEnter: authGuard,
  },
  {
    path: '/reports/lawyer-performance-report',
    name: 'LawyerPerformanceReport',
    component: LawyerPerformanceReport,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/reports/lawyer-my-performance-report',
    name: 'LawyerMyPerformanceReport',
    component: LawyerMyPerformanceReport,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/reports/case-performance-report',
    name: 'CasePerformanceReport',
    component: CasePerformanceReport,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/reports/magam-performances',
    name: 'MagamPerformances',
    component: MagamPerformances,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/my_cases',
    name: 'MyCases',
    component: MyCases,
    beforeEnter: authGuard,
  },
  {
    path: '/invoice_pdf_creator',
    name: 'InvoicePdfCreator',
    component: InvoicePdfCreator,
    beforeEnter: authGuard,
  },
  {
    path: '/schedules',
    name: 'Schedules',
    component: Schedules,
    beforeEnter: authGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
