import Vue      from 'vue'
import Vuex     from 'vuex'
import EventBus from '@/utils/event-bus'
import approvals                from './modules/approvals'
import caseJurisdictions        from './modules/case_jurisdictions'
import changeLogs               from './modules/change_logs'
import commissions              from './modules/commissions'
import firms                    from './modules/firms'
import contacts                 from './modules/contacts'
import customFields             from './modules/custom_fields'
import dashboardViews           from './modules/dashboard_views'
import deposits                 from './modules/deposits'
import entities                 from './modules/entities'
import expenses                 from './modules/expenses'
import investments              from './modules/investments'
import invoices                 from './modules/invoices'
import invoiceEmployeeEarnings  from './modules/invoice_employee_earnings'
import schedules                from './modules/schedules'
import timesheetEntries         from './modules/timesheet_entries'
import users                    from './modules/users'
import lawyerPerformanceReports from './modules/lawyer_performance_reports'
import casePerformanceReports   from './modules/case_performance_reports'
import codeLists                from './modules/code_lists'
import magams                   from './modules/magams'
import cardHistories            from './modules/card_histories'
import exclusiveCosts           from './modules/exclusive_costs'
import timesheetRates           from './modules/timesheet_rates'
import { getField, updateField } from 'vuex-map-fields'


Vue.use(Vuex)

const state = () => ({
  isLoading: false,
  isSidepanelFullWidth: false,
  showPopover: false,
  showModal: false,
  showNavMenu: false,
  showSidepanel: false,
  selectedSuggest: '',
  sidepanelContentComponent: '',
  modalSize: {
    width: 0,
    height: 0,
  },
  isSearching: false,
  showAdvancedSearch: false,
})

const getters = {
  showOverlay: state => {
    return state.showNavMenu || state.showModal || state.showPopover || state.selectedSuggest
  },
  showMainPageLoadingIndicator: state => {
    return state.isLoading && !state.showSidepanel
  },
  getField,
}

const mutations = {
  modalToggle (state) {
    state.showModal = !state.showModal
  },
  setModal (state, setModal) {
    state.showModal = setModal
  },
  setModalSize (state, modalSize) {
    state.modalSize.height = modalSize.height
    state.modalSize.width  = modalSize.width
  },
  searchControlToggle (state, isOpen) {
    state.isSearching = isOpen
  },
  showModalToggle (state, isOpen) {
    state.showModal = isOpen
  },
  showNavMenuToggle (state, isOepn) {
    state.showNavMenu = isOepn
  },
  setShowSidepanel (state, openSidepanel) {
    state.showSidepanel = openSidepanel
  },
  setIsLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  setShowPopover (state, showPopover) {
    state.showPopover = showPopover
  },
  setSelectedSuggest (state, selectedSuggest) {
    state.selectedSuggest = selectedSuggest
  },
  setSidepanelContentComponent (state, componentName) {
    state.sidepanelContentComponent = componentName
  },
  setIsSidepanelFullWidth (state, bool) {
    state.isSidepanelFullWidth = bool
  },
  updateField,
}

const actions = {
  modalClose ({ commit }) {
    EventBus.$emit('modalClose')
    commit ('setModalSize', { height: 0, width: 0 })
    commit('showModalToggle', false)
  },
  modalOpen ({ commit }, modalSize) {
    let modalSizeUpdate = modalSize
    if (!modalSize) {
      modalSizeUpdate = {
        height: 0,
        width: 0
      }
    }
    commit ('setModalSize', modalSizeUpdate)
    commit('showModalToggle', true)
  },
  navMenuClose ({ commit }) {
    commit('showNavMenuToggle', false)
  },
  navMenuOpen ({ commit }) {
    commit('showNavMenuToggle', true)
  },
  searchClose ({ commit }) {
    commit('searchControlToggle', false)
  },
  searchOpen ({ commit }) {
    commit('searchControlToggle', true)
  },
  resetSidepanelContentComponent ({ commit }) {
    commit('setSidepanelContentComponent', '')
  },
  sidepanelClose ({ commit, dispatch }) {
    dispatch('resetSidepanelContentComponent')
    commit('setShowSidepanel', false)
    commit('setIsSidepanelFullWidth', false)
  },
  sidepanelOpen ({ commit, state }, params) {
    commit('setSidepanelContentComponent', params.componentName)
    if (params.isFull) {
      commit('setIsSidepanelFullWidth', true)
    }
    if (!state.showSidepanel) {
      commit('setShowSidepanel', true)
    }

  },
  setSidepanelFullscreen ({ commit }) {
    commit('setIsSidepanelFullWidth', true)
  },
  setSidepanelHalf ({ commit }) {
    commit('setIsSidepanelFullWidth', false)
  },
  activateLoading ({ commit }) {
    commit('setIsLoading', true)
  },
  deactiveLoading ({ commit }) {
    commit('setIsLoading', false)
  },
}

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    caseJurisdictions,
    changeLogs,
    commissions,
    firms,
    contacts,
    customFields,
    dashboardViews,
    entities,
    investments,
    invoices,
    invoiceEmployeeEarnings,
    users,
    expenses,
    timesheetEntries,
    approvals,
    schedules,
    deposits,
    lawyerPerformanceReports,
    casePerformanceReports,
    codeLists,
    magams,
    cardHistories,
    exclusiveCosts,
    timesheetRates,
  },
  strict: 'true'
})
